<template>
  <section>
    <div class="mx-2">
      <v-card style="height: calc(100vh - 20px)" flat>
        <v-card-title>
          <span class="text-h4">Sea2Sea Support and Documentation</span>
        </v-card-title>
        <v-card-subtitle>
          <span>Provided by Unixity LLC & OpenAI</span>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  Support Chat
                </v-card-title>
                <v-card-text>
                  <v-form class="text-right" @submit.prevent="">
                    <v-textarea outlined rows="10" hint="What do you need assistance with? (Powered by Open AI & ChatGPT 4)" label="Message"></v-textarea>
                    <v-btn type="submit" color="primary" disabled>Send message</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>
                  Tips and Documentation
                </v-card-title>
                <v-card-text>
                  <v-form @submit.prevent="doSearchTips">
                    <v-text-field outlined label="Search" v-model="search"></v-text-field>
                  </v-form>
                  <div v-if="isLoading">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                  </div>
                  <div style="height: 60vh; overflow-y: scroll; overflow-x: hidden;" class="ma-2" v-else-if="availableTips.length >= 1">
                    <v-expansion-panels class="pa-4">
                      <v-expansion-panel v-for="(row, index) in availableTips" :key="index">
                        <v-expansion-panel-header>
                          <span class="title">
                              {{ row.title }}
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                            <v-divider></v-divider>
                            <div v-html="row.content" class="my-4"></div>
                            <v-divider></v-divider>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div v-else class="text-center mb-10">
                    <span class="title">No documentation or tips available</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="showProgramming = true" class="mr-2">
                Application information
              </v-btn>
              <v-btn @click="showWelcome = true">
                Show Welcome Guide
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-dialog v-model="showProgramming" persistent max-width="1200px">
        <v-card>
          <v-card-title>
            Sea2Sea Scuba Enterprise Web Application Version {{ applicationVersion }}
          </v-card-title>
          <v-card-text>
            This application was built using <a target="_blank" href="https://github.com/vuejs/core">Vue 2.6.11</a>, <a target="_blank" href="https://github.com/vuetifyjs/vuetify">Vuetify 2.6.14</a> , and <a target="_blank" href="https://github.com/electron/electron">Electron 13.0</a>.
            <br>
            <br>
            Thank you to the Open Source Community for allowing the internet to grow and allow applications like this to exist. We really have them all to thank for true Innovation.
            <br>
            <br>
            This application was Proudly Developed by Matthew Maggio.
            <br>
            <br>
            A Special thank you is made to Colin S. and Matt B. - You're dedication and support drives me forward and I'm proud to call you guys my mentors and friends.
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showProgramming = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showWelcome" persistent max-width="1200px">
        <welcome-tutorial @complete="showWelcome = false"></welcome-tutorial>
      </v-dialog>
    </div>
  </section>
</template>

<script>
import WelcomeTutorial from '@/components/admin/welcomeTutorial.vue'

export default {
  name: 'index',
  components: { WelcomeTutorial },
  data: () => ({
    search: String(),
    isLoading: false,
    showWelcome: false,
    showProgramming: false,
    tips: [{
      title: 'How do I make a new Course?',
      content: 'Testing'
    }
    ]
  }),
  computed: {
    availableTips () {
      if (this.search === String()) {
        return this.tips
      }

      return this.tips.filter(tip => {
        if (tip.title.toLowerCase().includes(this.search.toLowerCase())) {
          return tip
        }
      })
    }
  },
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.controllerTip.doReadAllActive().then(tips => {
        this.tips = tips
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doSearchTips () {
    // Search the tips array
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>

</style>
